import { Stack } from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { capitalize } from 'lodash'
import Link from 'next/link'

import Center from '~/components/Center'

type NotFoundProps = {
  entity?: string
}

export default function NotFound({ entity = 'page' }: NotFoundProps) {
  return (
    <Center.Middle>
      <Stack spacing={10} alignItems="center">
        <Typography variant="h1">404</Typography>

        <Stack spacing={0} alignItems="center">
          <Typography variant="h4">{capitalize(entity)} Not Found ⚠️</Typography>
          <Typography variant="body2">We couldn&prime;t find the {entity} you are looking for.</Typography>
        </Stack>

        <Button href="/" component={Link} variant="contained" sx={{ px: 5.5 }}>
          Back to Home
        </Button>
      </Stack>
    </Center.Middle>
  )
}
